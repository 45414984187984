import React from 'react';
import './ThreeSteps.css';
import { GetWebData } from '../Context/Context';

const ThreeSteps = () => {

    const { loading, stepsPageContent } = GetWebData();


    return (
        <>
            {
                loading ? "" :
                    <div className='three-steps-main mt-5'>
                        <div className="container py-5">
                            <div className="row py-5">
                                <div className="col-md-7 three-steps-page-thumbnail">
                                    <img src={stepsPageContent?.thumbnail} alt="" className="img-fluid" />
                                </div>
                                <div className="col-md-5 three-steps-page-steps">
                                    <h1 className='three-steps-page-title page-title mt-5'><strong>{stepsPageContent?.pageTitle}</strong></h1>
                                    <div className="step-items mt-5 pt-3">
                                        {stepsPageContent?.steps?.map((item, index) =>
                                            <div className="step-item d-flex mb-5">
                                                <div className="step-item-num">
                                                    <div className="step-item-num-title d-flex align-items-center justify-content-center rounded-circle"><span className=''>{item?.num}</span></div>
                                                </div>
                                                <div className="step-item-content">
                                                    <h6 className="step-item-title mt-2">{item?.title}</h6>
                                                    <div className="w-75">
                                                        <span className="step-item-description mt-3">
                                                            {item?.content}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default ThreeSteps;