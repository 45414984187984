import React from 'react';
import './Banner.css';
import { GetWebData } from '../Context/Context';

const Banner = () => {

    const { loading, bannerContent } = GetWebData()


    // Handle Links
    const handleLinks = (linkType, link) => {
        if (linkType) {
            window.open(link, "_blank")
        } else {
            window.open("https://asrafulweb.com/" + link, "_self")
        }
    }

    return (
        <>
            {
                loading ? "" :
                    <div className='banner-main'>
                        <div className="container">
                            <div className="row">                                
                            <div className="mobile-banner-thum">
                                    <img src={bannerContent?.img} alt="" className="img-fluid" />
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <h1 className='banner-headlaine mt-5'><strong>{bannerContent?.headline}</strong></h1>
                                    <h5 className='banner-subHeadline mt-3'>{bannerContent?.subHeadline}</h5>
                                    <div className="banner-links mt-5 pt-3">
                                        {
                                            bannerContent?.buttons?.map(item => <button className="banner-button-item me-4 py-3 px-5 rounded mb-3" onClick={() => handleLinks(item?.linkType, item?.link)} style={{ backgroundColor: `${item?.bgColor}` }}>{item.name}</button>)
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 pc-banner-thum">
                                    <img src={bannerContent?.img} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default Banner;