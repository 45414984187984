import React from 'react';
import './FewReasons.css';
import { GetWebData } from '../Context/Context';

const FewReasons = () => {

    const { loading, fewReasonsPageContent } = GetWebData()
    return (
        <>
            {
                loading ? "" :
                    <div className='few-reasons-main my-5'>
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-md-5">
                                    <h1 className='few-reasons-page-title page-title mt-5'><strong> {fewReasonsPageContent?.title}</strong></h1>
                                    <div class="accordion accordion-flush few-reasons-page-items mt-5" id="accordionFlushExample">
                                        {
                                            fewReasonsPageContent?.reasons?.map((item, index) =>
                                                <div class="accordion-item few-reasons-item mb-4">
                                                    <h2 class="accordion-header">
                                                        <button class={index === 0 ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${index + 1}`} aria-expanded="false" aria-controls={`flush-collapse-${index + 1} d-flex collapse-header`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                                            </svg>
                                                            <h4 className='ms-3'>{item.title}</h4>
                                                        </button>
                                                    </h2>
                                                    <div id={`flush-collapse-${index + 1}`} class={index === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse"} data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body">{item.description}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <img src={fewReasonsPageContent.thumbnail} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default FewReasons;