import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyADcKQZ4SyDItK_D-urMiHc0pJqTskKNO4",
    authDomain: "asrafulweb-co.firebaseapp.com",
    databaseURL: "https://asrafulweb-co-default-rtdb.firebaseio.com",
    projectId: "asrafulweb-co",
    storageBucket: "asrafulweb-co.appspot.com",
    messagingSenderId: "778090767836",
    appId: "1:778090767836:web:9845f70f281f90df641b19"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);