import React, { useState, useContext, createContext } from 'react';
import { child, get, ref } from 'firebase/database';
import { useEffect } from 'react';
import { database } from '../../firebase.config';

// Create context
const DataContext = createContext()

// context provider
export const DataContextProvider = (props) => {
    const data = ContextItems()
    return <DataContext.Provider value={data}>{props.children}</DataContext.Provider>
}

// use context
export const GetWebData = () => useContext(DataContext)

const ContextItems = () => {

    const [loading, setLoading] = useState(true)
    const [bannerContent, setBannerContent] = useState(null)
    const [servicesItems, setServicesItems] = useState(null)
    const [stepsPageContent, setStepsPageContent] = useState(null)
    const [fewReasonsPageContent, setFewReasonsPageContent] = useState(null)
    const [projectsContent, setProjectsContent] = useState(null)


    useEffect(() => {
        get(child(ref(database), '/'))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    setBannerContent(snapshot.val().bannerContent)
                    setServicesItems(snapshot.val().servicesItems)
                    setStepsPageContent(snapshot.val().stepsPageContent)
                    setFewReasonsPageContent(snapshot.val().fewReasonsPageContent)
                    setProjectsContent(snapshot.val().projectsContent)
                    setLoading(false)
                }
            })
    }, [])


    return {
        loading,
        bannerContent,
        servicesItems,
        stepsPageContent,
        fewReasonsPageContent,
        projectsContent
    }
};
