import './App.css';
import './responsive.css';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Services from './components/Services/Services';
import ThreeSteps from './components/ThreeSteps/ThreeSteps';
import FewReasons from './components/FewReasons/FewReasons';
import Projects from './components/Projects/Projects';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { GetWebData } from './components/Context/Context';



function App() {

  const {loading} = GetWebData()


  return (
    <div className="App">
      <Router>
        {
          loading ?
            <div className='home-loading d-flex justify-content-center align-items-center' style={{height: "100vh"}}>
              <div class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            :
            <div>
              <Switch>
                <Route exact path="/">
                  <Header />
                  <Banner />
                  <Services />
                  <ThreeSteps />
                  <FewReasons />
                  <Projects />
                  <ContactUs />
                  <Footer />
                </Route>
                <Route path="*">
                  <ErrorPage />
                </Route>
              </Switch>
            </div>
        }
      </Router>
    </div>
  );
}

export default App;
