import React, { useEffect, useState } from 'react';
import './Projects.css'
import { GetWebData } from '../Context/Context';

const Projects = () => {

    const [projectsItemsEven, setProjectsItemsEven] = useState(null)
    const [projectsItemsOdd, setProjectsItemsOdd] = useState(null)

    const { loading, projectsContent } = GetWebData()

    useEffect(() => {
        if (projectsContent?.projects) {
            setProjectsItemsEven(projectsContent?.projects.filter(project => project.num % 2 === 0))
            setProjectsItemsOdd(projectsContent?.projects.filter(project => project.num % 2 !== 0))
        }
    }, [])

    return (
        <>
            {
                loading ? "" :
                    <div className='projects-main py-5 ' id='projects'>
                        <div className="container py-5">
                            <div className="row py-5">
                                <div className="col-md-5">
                                    <h1 className='page-title projects-page-title'><strong>{projectsContent?.pageTitle}</strong></h1>
                                    <h4 className='projects-page-sub-title mt-3'>{projectsContent?.subTitle}</h4>
                                    {
                                        projectsItemsEven?.map(projectItem => (
                                            <div className="project-item mt-5 pt-5">
                                                <img src={projectItem.thumbnail} alt="" className="img-fluid rounded-4 project-item-thumbnail" />
                                                <br />
                                                <div className="project-content-text mt-5">
                                                    <h6 className="project-category text-uppercase">~ {projectItem.category}</h6>
                                                    <a className="project-name-link" target='blank' href={projectItem.link} ><h3 className="project-name">{projectItem.name}</h3></a>
                                                </ div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-5">
                                    {
                                        projectsItemsOdd?.map(projectItem => (
                                            <div className="project-item mt-5 pt-5">
                                                <img src={projectItem.thumbnail} alt="" className="img-fluid rounded-4 project-item-thumbnail" />
                                                <br />
                                                <div className="project-content-text mt-5">
                                                    <h6 className="project-category text-uppercase">~ {projectItem.category}</h6>
                                                    <a className="project-name-link" target='blank' href={projectItem.link} ><h3 className="project-name">{projectItem.name}</h3></a>
                                                </ div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default Projects;