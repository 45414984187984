import React from 'react';

const Footer = () => {
    return (
        <footer className='footer-main' style={{background: "#494949"}}>
            <div className="container py-4 text-light">
                <sapn className=''>Copyright © {new Date().getFullYear()} All Rights Reserved By AsrafulWeb</sapn>
            </div>
        </footer>
    );
};

export default Footer;