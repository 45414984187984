import React from 'react';
import './Services.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { GetWebData } from '../Context/Context';

const Services = () => {

    const { loading, servicesItems } = GetWebData();

    return (
        <>
            {
                loading ? "" :
                    <div className='services-main' style={{ backgroundImage: "url(https://aw-medias.netlify.app/bg/services-bg.jpg)", backgroundPosition: "center", backgroundSize: "cover", minHeight: "500px" }}>
                        <div className="container pt-4">
                            <div className="row pt-4 services-items">
                                {
                                    servicesItems?.map((item, index) => (
                                        <div className="col-md-3">
                                            <div className="services-item px-4 pb-4 pt-5 mx-1 mb-4" >
                                                <img src={item.iconsrc} alt="" className="img-fluid" style={{ width: "50px" }} />
                                                <h5 className='mt-3 service-item-title'>{item.title}</h5>
                                                <div className='service-item-description mt-4'>{item.description}</div>
                                                <br />
                                                <Link to="" className="d-flex mt-4 service-item-link pb-3" style={{ color: `${item.color}` }}>
                                                    <span>Learn More</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-arrow-right-short mt-1 ms-2" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                                    </svg>
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default Services;