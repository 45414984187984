import React from 'react';
import { Link } from "react-router-dom";
import './Header.css';

const Header = () => {

    const HeaderLogos = [
        {
            name: 'Light Theme Logo',
            src: 'https://aw-medias.netlify.app/logo/asrafulweb-logo-dark.png',
            active: true
        },
        {
            name: 'Dark Theme Logo',
            src: 'https://aw-medias.netlify.app/logo/asrafulweb-logo-light.png',
            active: false
        }
    ]

    const HeaderLinks = [
        {
            title: 'Home',
            link: '/'
        },
        {
            title: 'Services',
            link: '#services'
        },
        {
            title: 'Projects',
            link: '#projects'
        },
        {
            title: 'Contact',
            link: '#contact'
        },
    ]

    const HeaderSocial = [
        {
            name: 'LinkedIn',
            link: 'https://linkedin.com/company/asrafulweb',
            src: "https://aw-medias.netlify.app/header-social-icons/in.png"
        },
        {
            name: 'Twitter',
            link: 'https://Twitter.com/asrafulweb',
            src: "https://aw-medias.netlify.app/header-social-icons/tw.png"
        },
        {
            name: 'Github',
            link: 'https://Github.com/asrafulweb',
            src: "https://aw-medias.netlify.app/header-social-icons/gh.png"
        },
    ]


    return (
        <div className='header-main'>
            <div className="container">
                <div className="header-top">
                    <div className="row">
                        <div className="col-8">
                            <Link to='/' className='header-top-left'>
                                <img src={HeaderLogos?.find(item => item.active === true).src} alt="" className="img-fluid py-3" style={{ maxWidth: "150px" }} />
                            </Link>
                        </div>
                        <div className="col-4 header-top-right d-flex justify-content-end">
                            <div className="header-top-right-theme-change-btn">
                                <h6 className='mt-3'><b>LIGHT</b></h6>
                            </div>
                            <div className="header-right-offcanvas-menu-controller">
                                <button class="btn border-1" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileOffcanvasSection" aria-controls="mobileOffcanvasSection">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-bottom mt-2">
                    <div className="row py-2 px-3">
                        <div className="col-md-6 d-flex header-links">
                            {
                                HeaderLinks?.map((item, index) => {
                                    return (
                                        <Link to={item.link} key={index} className="px-4 text-dark header-link-item" style={{ textDecoration: "none" }}>
                                            <h6 className='my-3'><b>{item.title}</b></h6>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                        <div className="col-md-6 d-flex justify-content-end align-items-center header-social-links">
                            {
                                HeaderSocial.map(item => (
                                    <a href={item.link} target="_blank" rel="noopener noreferrer" key={item.name} className="header-social-item" style={{ textDecoration: "none" }}>
                                        <img src={item.src} className='img-fluid ms-3' style={{ width: "25px" }} alt="" />
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="mobile-header">
                    <div class="offcanvas offcanvas-start pt-3 mobile-offcanvas" tabindex="-1" id="mobileOffcanvasSection" aria-labelledby="mobileOffcanvasSectionLabel">
                        <div class="offcanvas-header">
                            <div class="offcanvas-title" id="mobileOffcanvasSectionLabel"></div>
                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body pt-5 text-center">
                            <div className="mobile-offcanvas-link">
                                {
                                    HeaderLinks?.map((item, index) => {
                                        return (
                                            <a href={item.link === "/" ? "/": "/"+item.link} key={index} className="px-4 text-dark header-link-item" style={{ textDecoration: "none" }}>
                                                <h6 className='my-3'><b>{item.title}</b></h6>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                            <div className="mobile-offcanvas-social-links pt-3">
                                {
                                    HeaderSocial.map(item => (
                                        <a href={item.link} target="_blank" rel="noopener noreferrer" key={item.name} className="header-social-item" style={{ textDecoration: "none" }}>
                                            <img src={item.src} className='img-fluid ms-3' style={{ width: "25px" }} alt="" />
                                        </a>
                                    ))
                                }
                            </div>
                            <div className="mobile-offcanvas-theme-controller pt-5">
                                <button class="btn btn-outline-dark px-4 btn-sm border-1" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileOffcanvasSection" aria-controls="mobileOffcanvasSection">Light</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header; 