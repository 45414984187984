import React from 'react';
import './ContactUs.css'

const ContactUs = () => {
    return (
        <div className='contact-us-main'>
            <div className="container py-5 my-5">
                <h1 className='contact-us-title mb-5 text-center'>Get In Touch</h1>
                <div className="d-flex justify-content-center">
                    <form className="contact-us-form rounded-5 py-5 col-sm-9">
                        <div className="py-4 text-center h4 contact-us-form-title">Send A Message</div>
                        <div class="row mt-5">
                            <div className="col-md-6 mb-5">
                                <label for="contactFormName" class="form-label h5 form-input-label">Your Full Name <span className='text-danger'>*</span></label>
                                <input type="text" class="form-control form-control-lg form-input" id="contactFormName" />
                                {/* <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <div className="col-md-6 mb-5 ">
                                <label for="contactFormEmail" class="form-label h5 form-input-label">Your Email <span className='text-danger'>*</span></label>
                                <input type="email" class="form-control form-control-lg form-input" id="contactFormEmail" />
                                {/* <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                        </div>
                        <div className="mb-5">
                            <label for="contactFormSubject" class="form-label h5 form-input-label">Subject <span className='text-danger'>*</span></label>
                            <input type="text" class="form-control form-control-lg form-input" id="contactFormSubject" />
                        </div>
                        <div className="mb-5">
                            <label for="contactFormMessage" class="form-label h5 form-input-label">Message <span className='text-danger'>*</span></label>
                            <textarea type="text" class="form-control form-control-lg form-input" rows="3" id="contactFormMessage"></textarea>
                        </div>
                        <div className="mb-2 mt-5 d-flex justify-content-center">
                            <button type='submit' className="btn btn-lg contact-us-form-submit-btn text-bold text-light">
                                <>SUBMIT</>
                            </button>
                        </div>
                        <div className="text-center">
                            <span className="cursor-pointer">Sent Email?</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;