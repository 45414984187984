import React from 'react';
import './ErrorPage.css';

const ErrorPage = () => {
    return (
        <div className='error-page-main'>
            <div className="container d-flex justify-content-center align-items-center">
                <div className="text-center error-page-content" style={{marginTop: "25%"}}>
                    <h1 className='error-page-error-number'>404</h1>
                    <h1 className='error-page-error-message my-4'>Page Not Found! </h1>
                    <div className=" text-center">
                        <a href="/" className="btn btn-success px-5 mt-4">Back To Home</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;